import React, { useEffect, useRef, useState } from "react";
import { Tab, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { MasterService } from "../../services";
import { CSVLink } from "react-csv";
import Loader from "../common/loader";
import DealerlistingTable from '../dealer-user-listing/DealerListingTable';
import ExecutiveListingTable from '../dealer-user-listing/ExecutiveListing'
const DealerUserListing = (props) => {
    const csvLink = useRef();
    const csvLink2 = useRef();
    const executiveTabRef = useRef(null);
    const [dealersData, setDealers] = useState(null);
    const [dealersDataCount, setDealersCount] = useState(0);
    const [executiveData, setExecutiveData] = useState(null);
    const [executiveDataCount, setExecutiveCount] = useState(0);
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
    const [loading, setLoading] = useState(false);
    const [pageDealerCount, setPageDealerCount] = useState(0)
    const [pageExecutiveCount, setPageExecutiveCount] = useState(0)
    const [selectedTab, setSelectedTab] = useState('dealer')
    const [csvData, setCsvData] = useState([])
    const [searchData, setSearchData] = useState({})
    const [isPageReset, setPageReset] = useState(0);
    const [isDealerReset, setDealerReset] = useState(0);
    const [isExecutiveReset, setExecutiveReset] = useState(0);
    const [isPopUpReset, setPopupReset] = useState(0);
    const historyLead = useNavigate();

    useEffect(() => {
        getDealerReporting(1, {})
        getExecutiveReporting(1, {}, true)
        getStateList()
        getCityList([], true)
        if (executiveTabRef.current && historyLead.action === 'POP') {
            executiveTabRef.current.click();
        }
        console.log("======selectedTab======", selectedTab);
    }, [])

    const handleSearchData = (search_data) => {
        setSearchData(search_data)
    }

    const getDealerReporting = async (page, searchData, page_reset) => {
        setLoading(true)
        if (page_reset) {
            setPageReset(isPageReset + 1)
        }
        let params = {group_by_user:true, "vertical_id": 1, "page": page, ...searchData }
        await MasterService.post('/sfa/dealer/dealerReportingResult', params)
            .then(function (response) {
                if (response.data.status === 200) {
                    setDealers(response.data.data.dealer_data);
                    if (response.data.data.allPackageListcount && response.data.data.allPackageListcount > 0) {
                        let tempPageCount = Math.ceil(response.data.data.allPackageListcount / response.data.data.per_page);
                        setDealersCount(response.data.data.allPackageListcount)
                        setPageDealerCount(tempPageCount);
                    } else {
                        setDealersCount(0)
                        setPageDealerCount(0);
                    }
                    setLoading(false)
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
                setLoading(false)
            });
    }

    const getExecutiveReporting = async (page, searchData, page_reset) => {
        setLoading(true)
        if (page_reset) {
            setPageReset(isPageReset + 1)
        }
        let params = { "vertical_id": 1, status: 1, "page": page, ...searchData }
        await MasterService.post('/sfa/dealer/executiveReportingResult', params)
            .then(function (response) {
                if (response.data.status === 200) {
                    setExecutiveData(response.data.data.excutive_data);
                    if (response.data.data.allPackageListcount) {
                        let tempPageCount = Math.ceil(response.data.data.allPackageListcount / response.data.data.per_page);
                        setExecutiveCount(response.data.data.allPackageListcount)
                        setPageExecutiveCount(tempPageCount);
                    } else {
                        setExecutiveCount(0)
                        setPageExecutiveCount(0);
                    }
                    setLoading(false)
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
                setLoading(false)
            });
    }

    const getStateList = async () => {
        MasterService.post('/sfa/dealer/dealer_state_list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    var state_list = response.data.data.map(elm => ({ id: elm.id, label: elm.state_name, value: elm.id }));
                    setStateList(state_list);
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
            });
    }

    const getCityList = async (state_id, state_change) => {
        if (!state_id && state_change) {
            setCityList([])
        } else {
            await MasterService.post('/sfa/dealer/dealer_city_list', { state_ids: [state_id.id] }).then(function (response) {
                if (response.data.status === 200) {
                    var city_list = response.data.data.map(elm => ({ id: elm.id, label: elm.city_name, value: elm.id, state_id: elm.state_id }));
                    setCityList(city_list);
                }
            }).catch(function (response) {
            });
        }
    }

    const handleClick = (value) => {
        setSelectedTab(value)
        setSearchData({})
        setPopupReset(isPopUpReset + 1)
        if (value === "dealer") {
            setDealerReset(isDealerReset + 1)
        } else {
            setExecutiveReset(isExecutiveReset + 1)
        }
    }

    const downloadData = async () => {
        setLoading(true);
        let csv_data = []
        if (selectedTab === "dealer") {
            searchData.apply_limit = 'no'
            csv_data = await exportDealerReportingData(1, searchData)
        } else {
            searchData.apply_limit = 'no'
            csv_data = await exportExecutiveReportingData(1, searchData)
        }
        setCsvData(csv_data)
        csvLink.current.link.click();
    }

    const exportDealersMappingData = async (page, searchData) => {
        setLoading(true)
        let params = { "vertical_id": 1, "page": page, ...searchData }
        let dealer_data = []
        await MasterService.post('/sfa/dealer/export-dealers-mapping-history', params)
            .then(function (response) {
                if (response.data.status === 200) {
                    dealer_data = response.data.data.csv_data
                    setLoading(false)
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
                setLoading(false)
            });
        return dealer_data
    }

    const exportExecutiveMappingData = async (page, searchData) => {
        setLoading(true)
        let params = { "vertical_id": 1,status:1, "page": page, ...searchData }
        let dealer_data = []
        await MasterService.post('/sfa/dealer/export-executive-mapping-history', params)
            .then(function (response) {
                if (response.data.status === 200) {
                    dealer_data = response.data.data.csv_data
                    setLoading(false)
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
                setLoading(false)
            });
        return dealer_data
    }

    const downloadAllMappedData = async () => {
        setLoading(true);
        let csv_data = []
        if (selectedTab === "dealer") {
            searchData.apply_limit = 'no'
            csv_data = await exportDealersMappingData(1, searchData)
        } else {
            searchData.apply_limit = 'no'
            csv_data = await exportExecutiveMappingData(1, searchData)
        }
        setCsvData(csv_data)
        csvLink2.current.link.click();
    }

    const exportExecutiveReportingData = async (page, searchData) => {
        setLoading(true)
        let params = { "vertical_id": 1,status:1, "page": page, ...searchData }
        let dealer_data = []
        await MasterService.post('/sfa/dealer/exportExecutiveReportingData', params)
            .then(function (response) {
                if (response.data.status === 200) {
                    dealer_data = response.data.data.csv_data
                    setLoading(false)
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
                setLoading(false)
            });
        return dealer_data
    }

    const exportDealerReportingData = async (page, searchData) => {
        setLoading(true)
        let params = { "vertical_id": 1, "page": page, ...searchData }
        let dealer_data = []
        await MasterService.post('/sfa/dealer/exportDealerReportingData', params)
            .then(function (response) {
                if (response.data.status === 200) {
                    dealer_data = response.data.data.csv_data
                    setLoading(false)
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
                setLoading(false)
            });
        return dealer_data
    }

    return (
        <React.Fragment>
            {loading ? <Loader /> : null}
            <div className="container-fluid">
                <div className="dealer-user-mapping-user">
                    <div className="top-heading d-flex">
                        <h1>Partner-User Mapping</h1>
                        <div className="">
                            <button className="btn-line btn-line-first">
                                <i className="ic-export m-xs-r"></i>
                                <span onClick={() => downloadAllMappedData()}>Export Mapping History</span>
                                <CSVLink
                                    data={csvData}
                                    filename={`${selectedTab}-mapping-list-${Math.round(+new Date() / 1000)}.csv`}
                                    data-interception='off'
                                    ref={csvLink2}
                                >
                                </CSVLink>
                            </button>
                            <button className="btn-line" style={{marginLeft:20}}>
                                <i className="ic-export m-xs-r"></i>
                                <span onClick={() => downloadData()}>Export Data</span>
                                <CSVLink
                                    data={csvData}
                                    filename={`${selectedTab}-list-${Math.round(+new Date() / 1000)}.csv`}
                                    data-interception='off'
                                    ref={csvLink}
                                >
                                </CSVLink>
                            </button>
                        </div>
                    </div>
                    <div className="dealer-user-mapping-tab-outer">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <div className="dealer-user-mapping-tabs">
                                <Nav variant="pills" className="flex-column tab-line">
                                    <div className="tab-list">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first" onClick={() => handleClick('dealer')}>All Partner ({dealersDataCount ? dealersDataCount : 0})</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link ref={executiveTabRef} eventKey="second" onClick={() => handleClick('executive')}> All Executive ({executiveDataCount ? executiveDataCount : 0})</Nav.Link>
                                        </Nav.Item>
                                    </div>
                                </Nav>
                            </div>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <DealerlistingTable
                                        dealersData={dealersData}
                                        pageCount={pageDealerCount}
                                        isPageReset={isPageReset}
                                        stateList={stateList}
                                        cityList={cityList}
                                        isReset={isDealerReset}
                                        getDealerReporting={getDealerReporting}
                                        handleSearchData={handleSearchData}
                                        getCityList={(e) => getCityList(e, true)}
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <ExecutiveListingTable
                                        executiveData={executiveData}
                                        pageCount={pageExecutiveCount}
                                        isPageReset={isPageReset}
                                        stateList={stateList}
                                        cityList={cityList}
                                        isReset={isExecutiveReset}
                                        isPopUpReset={isPopUpReset}
                                        getExecutiveReporting={getExecutiveReporting}
                                        getCityList={(e) => getCityList(e, true)}
                                        handleSearchData={handleSearchData}
                                    />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default DealerUserListing;