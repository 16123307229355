import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams, NavLink,useSearchParams } from 'react-router-dom';
import RoleDetails from "./AddUserRoleDetails"
import BasicDetails from "./BasicDetails"
import AddDocuments from "./AddDocuments"
import PreviewConfirm from "./PreviewConfirm"
import MasterService from '../../services/MasterService';
import { toast } from 'react-toastify';
import {  defaultZone } from '../../config/constant';
import Loader from '../common/loader'; 

const EditUser = (props) => {
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
    const [activePageId, setActivePageId] = useState('');
    const [formFillStatusArr, setFormFillStatusList] = useState([]);
    const [user_detail, setUserDetail] = useState({});
    const [loading, setLoading] = useState(false);
    const [ConfigList, setConfigList] = useState([])
    const [user_id, setUserId] = useState(null)
    const [show_detail, setShowDetail] = useState(false)
    const [userLocationDetail, setUserLocationDetail] = useState({})
    const [user_logout, setUserLogout] = useState(0)
    const urlprefix = '/edit-user';
    const queryParams = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [role,setRole] = useState(null);

    const menuList = [
        {
            id: 1,
            index: 1,
            sub_menu: "Role Details",
            sub_menu_url: "role_details"
        },
        {
            id: 4,
            index: 4,
            sub_menu: "Preview and Confirm",
            sub_menu_url: "preview_and_confirm"
        }]

    const historyLead = useNavigate();

    useEffect(() => {
        let search_user_id = searchParams.get("id") ? searchParams.get("id") : null 
 
        if (search_user_id) {
            setUserId(search_user_id)
            getUserDetail(search_user_id)
        }
        getStateList()
        getCityList()
        // checkValidPath()
    }, [])

    const getUserDetail = async (user_id) => {
        setLoading(true)
        await MasterService.post('/sfa/user/get-view-detail', { user_id }).then(async function (response) {
            if (response.data.status === 200 && response.data.data && response.data.data.status !=='5') {
                let user_data = response.data.data                
                let temp_user_detail = { ...user_detail }

                getConfigList(user_data.role_id)
                let role_details = {
                    role: user_data.role_id,
                    employee_id: user_data.employe_id,
                    username: user_data.name,
                    email_id: user_data.email,
                    mobile_no: user_data.mobile,
                    reporting_role: user_data.reporting_response.length > 0 && user_data.reporting_response[0].manager_role_id,
                    reporting_manager: user_data.reporting_response.length > 0 && user_data.reporting_response[0].manager_id,
                    zone_id: defaultZone,
                    location_response: user_data.location_response,
                    previous_role_id:user_data.role_id, 
                    previous_manager_id : user_data.reporting_response.length > 0 && user_data.reporting_response[0].manager_id,
                    business_tagging: user_data.business_tagging || ''
                }
                setRole(role_details.role);
                let basic_details = {
                    created_date: user_data.created_date && isNaN(Date.parse(user_data.created_date)) === false ? new Date(user_data.created_date) : "",
                    approval_date: user_data.approval_date && isNaN(Date.parse(user_data.approval_date)) === false ? new Date(user_data.approval_date) : "",
                    resign_date_1: user_data.resign_date_1 && isNaN(Date.parse(user_data.resign_date_1)) === false ? new Date(user_data.resign_date_1) : "",
                    resign_date_2: user_data.resign_date_2 && isNaN(Date.parse(user_data.resign_date_2)) === false ? new Date(user_data.resign_date_2) : "",
                    rejoin_date_1: user_data.rejoin_date_1 && isNaN(Date.parse(user_data.rejoin_date_1)) === false ? new Date(user_data.rejoin_date_1) : "",
                    rejoin_date_2: user_data.rejoin_date_2 && isNaN(Date.parse(user_data.rejoin_date_2)) === false ? new Date(user_data.rejoin_date_2) : "",
                    warning_date_1: user_data.warning_date_1 && isNaN(Date.parse(user_data.warning_date_1)) === false ? new Date(user_data.warning_date_1) : "",
                    warning_date_2: user_data.warning_date_2 && isNaN(Date.parse(user_data.warning_date_2)) === false ? new Date(user_data.warning_date_2) : "",
                    onboarded_from: user_data.user_source ? user_data.user_source : "",
                    onboarded_by: (user_data.user_source && user_data.user_source==='admin') ? user_data.created_by_admin_name : user_data.created_by_user_name,
                    hiring_source: user_data.hiring_source ? user_data.hiring_source : "",
                    comments: user_data.comments ? user_data.comments : "",
                    is_tax_booster_enable: user_data.is_tax_booster_enable === "0" ? false : true,
                    profile_image: user_data.profile_image_path,
                    profile_image_name: user_data.profile_image_name,
                    is_mobile_no_verified: user_data.is_mobile_no_verified ? user_data.is_mobile_no_verified : "0",
                    username: user_data.name,
                    mobile_no: user_data.mobile,
                    email_id: user_data.email,
                    adhar_no: user_data.adhar_no,
                    employment_type: user_data.employe_type,
                    employee_id: user_data.employe_id,
                    joining_date: user_data.doj && isNaN(Date.parse(user_data.doj)) === false ? new Date(user_data.doj) : "",
                    anniversary_date: user_data.anniversary_date && isNaN(Date.parse(user_data.anniversary_date)) === false ? new Date(user_data.anniversary_date) : "",
                    bank_id: user_data.bank_response ? user_data.bank_response.sfa_bank_id : "",
                    bank_branch_name: user_data.bank_response ? user_data.bank_response.branch_name : "",
                    bank_account_number: user_data.bank_response ? user_data.bank_response.account_number : "",
                    confirm_bank_account_number: user_data.bank_response ? user_data.bank_response.account_number : "",
                    beneficiary_name: user_data.bank_response ? user_data.bank_response.beneficiary_name : "",
                    ifsc_code: user_data.bank_response ? user_data.bank_response.ifsc_code : "",
                    npwp: user_data.bank_response ? user_data.bank_response.NPWP : "",
                    npwp_disabled: ((user_data.id && user_data.id!== '') && (user_data.status === '1' || user_data.status === '0') && (user_data.bank_response && user_data.bank_response.NPWP))?true:false,
                    date_of_birth: user_data.dob && isNaN(Date.parse(user_data.dob)) === false ? new Date(user_data.dob) : "",
                    state_name: user_data.user_state,
                    city: user_data.user_city,
                    user_location: user_data.user_location,
                    current_occupation: user_data.current_occupation_id,
                    company_name: user_data.company_name,
                    designation: user_data.designation_id,
                    tagging_id: user_data.tagging_id,
                    business_line: user_data.business_line,
                    source: user_data.source_id,
                    postal_code: user_data.pincode,
                    pan_no: user_data.pan_no, 
                    street_address: user_data.address1,
                    house_number: user_data.house_no,
                    incentive_percentage: user_data.incentive_percentage,
                    address: user_data.address,
                    password: user_data.password,
                    confirm_password: user_data.password,
                    referral_name: user_data.referral_user_id,
                }
                let documents = user_data.document_response
                temp_user_detail["role_details"] = role_details
                temp_user_detail["basic_details"] = basic_details
                temp_user_detail["user_id"] = user_data.id
                temp_user_detail["status"] = user_data.status
                temp_user_detail["documents"] = documents
                setUserDetail(temp_user_detail)
                getUserLocationDetail(user_data.id, temp_user_detail, user_data)


            } else {
                toast.error("Invalid request")
                historyLead("/user-management-detail")
            }
        })
    }

    const getUserLocationDetail = async (user_id, temp_user_detail, user_data) => {
        await MasterService.post('/sfa/user/get-user_location', { user_id }).then(function (response) {
            if (response.data.status === 200) {
                let data = response.data.data
                let state_data = data.location_response.map(({ state_id }) => state_id)
                state_data = state_data.filter((item, i, ar) => ar.indexOf(item) === i);
                let city_data = data.location_response.map(({ city_id }) => city_id)
                city_data = city_data.filter((item, i, ar) => ar.indexOf(item) === i);
                temp_user_detail.role_details.state = state_data
                temp_user_detail.role_details.city = city_data

                let temp_user_loc = { ...userLocationDetail }
                temp_user_loc.state_data = state_data
                temp_user_loc.city_data = city_data
                setUserLocationDetail(temp_user_loc)
                setUserDetail(temp_user_detail)
                getActivePageId(user_data)
                setShowDetail(true)
                setLoading(false)
            }
        })
    }

    // const checkValidPath = () => {
    //     if (Object.keys(user_detail).length === 0) {
    //         handleReset()
    //         historyLead('/edit-user/role_details');
    //     }
    // }

    const getActivePageId = async (user_data) => {
        if (user_data.role_id) {
            let tempformFillStatusArr = formFillStatusArr
            setActivePageId(menuList[0].id)
            if (!tempformFillStatusArr.includes(menuList[0].id)) {
                tempformFillStatusArr.push(menuList[0].id)
            }
            setFormFillStatusList(tempformFillStatusArr)
        }
        if (user_data.email) {
            let tempformFillStatusArr = formFillStatusArr
            setActivePageId(menuList[1].id)
            if (!tempformFillStatusArr.includes(menuList[1].id)) {
                tempformFillStatusArr.push(menuList[1].id)
            }
            setFormFillStatusList(tempformFillStatusArr)
        }
        if (user_data.document_response && user_data.document_response.length > 0) {
            let tempformFillStatusArr = formFillStatusArr
            setActivePageId(menuList[2].id)
            if (!tempformFillStatusArr.includes(menuList[2].id)) {
                tempformFillStatusArr.push(menuList[2].id)
            }
            setFormFillStatusList(tempformFillStatusArr)
        }
    }

    const getConfigList = async (role_id) => {
        MasterService.post('/sfa/user/get-config', { role_id: role_id })
            .then(function (response) {
                if (response.data.status === 200) {
                    setConfigList(response.data.data)
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
            });
    }

    const getStateList = async () => {
        MasterService.post('/sfa/user/state_list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    var state_list = response.data.data.map(elm => ({ id: elm.id, label: elm.state_name, value: elm.id }));
                    setStateList(state_list);
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
            });
    }

    const getCityList = async (state_ids) => {
        MasterService.post('/sfa/user/city_list', { state_ids })
            .then(function (response) {
                if (response.data.status === 200) {
                    var city_list = response.data.data.map(elm => ({ id: elm.id, label: elm.city_name, value: elm.id, state_id: elm.state_id }));
                    setCityList(city_list);
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
            });
    }

    const handleReset = () => {
        setActivePageId('')
        setFormFillStatusList([])
    }

    const handleSave = async (element, name, save_detail) => {
        let temp_user_detail = { ...user_detail }
        temp_user_detail[name] = element
        setUserDetail(temp_user_detail)
        if(save_detail){
            handleCreateUser(temp_user_detail[name])
        }
            
        let index = menuList.findIndex(x => x.sub_menu_url === name);
        let next_element = menuList[index + 1]
        let current_element = menuList[index]
        setActivePageId(next_element.id)
        let tempformFillStatusArr = formFillStatusArr
        if (!tempformFillStatusArr.includes(current_element.id)) {
            tempformFillStatusArr.push(current_element.id)
        }
        setFormFillStatusList(tempformFillStatusArr)
    }

    const uploadProfileImages = async (profile_image_file) => {
        let profile_data = {}
        var formData = new FormData();
        formData.append("dealer_id", "0");
        formData.append("upload_type", "dealer_docs");
        formData.append("visit_id", `user/${user_id}`);
        formData.append("images", profile_image_file);
        await MasterService.uploadFilesToS3(formData, {
            "Content-Type": "multipart/form-data",
        }).then((result) => {
            if (result.data.status === 200) {
                profile_data.profile_image_name = result.data.data[0].file_name;
                profile_data.profile_image_path = result.data.data[0].file_url;
            }
            toast.success("Profile Image uploaded")
        })
        return profile_data
    }

    const uploadImages = async (added_documents) => {
        let document_info = []
        for (let index = 0; index < added_documents.length; index++) {
            let element = added_documents[index]
            let doc_object = {}
            if (element.doc_url.includes("blob")) {
                var formData = new FormData();
                formData.append("dealer_id", "0");
                formData.append("upload_type", "dealer_docs");
                formData.append("visit_id", `user/${user_id}`);
                formData.append("images", element.doc_file);
                await MasterService.uploadFilesToS3(formData, {
                    "Content-Type": "multipart/form-data",
                }).then((result) => {
                    if (result.data.status === 200) {
                        let config_doc = ConfigList.document.filter(e => e.id === element.document_id)
                        doc_object.document_id = element.document_id
                        doc_object.doc_url = result.data.data[0].file_url;
                        toast.success(config_doc[0].doc_title + " uploaded")
                    }
                    document_info.push(doc_object)
                })
            } else {
                doc_object.document_id = element.document_id
                doc_object.doc_url = element.doc_url
                document_info.push(doc_object)
            }
        }
        return document_info
    }

    const handleCreateUser = async (user_basic_details) => {
        setLoading(true)
        let profile_data = {}
        let basic_details = {}
        if (user_basic_details && Object.keys(user_basic_details).length) {
            basic_details = user_basic_details
        } else {
            basic_details = user_detail.basic_details ? user_detail.basic_details : {}
        }
        let { role_details, documents } = user_detail
        if (basic_details.profile_image_file) {
            profile_data = await uploadProfileImages(basic_details.profile_image_file)

        } else {
            profile_data = {
                profile_image_name: basic_details.profile_image_name ? basic_details.profile_image_name : "",
                profile_image_path: basic_details.profile_image ? basic_details.profile_image : ""
            }
        }
        let modified_documents = await uploadImages(documents)
        // let state_data = stateList.filter((e) => role_details.state.includes(e.id))
        let city_data = cityList.filter((e) => role_details.city.includes(e.id))
        let stateListTemp = city_data.map(({ state_id }) => state_id)
        stateListTemp = stateListTemp.filter((item, i, ar) => ar.indexOf(item) === i);

        let state_data = stateList.filter((e) => stateListTemp.includes(e.id))
        let params = {
            is_update:1,
            current_role:role,
            created_date: basic_details.created_date && isNaN(Date.parse(basic_details.created_date)) === false ? new Date(basic_details.created_date) : "",
            approval_date: basic_details.approval_date && isNaN(Date.parse(basic_details.approval_date)) === false ? new Date(basic_details.approval_date) : "",
            resign_date_1: basic_details.resign_date_1 && isNaN(Date.parse(basic_details.resign_date_1)) === false ? new Date(basic_details.resign_date_1) : "",
            resign_date_2: basic_details.resign_date_2 && isNaN(Date.parse(basic_details.resign_date_2)) === false ? new Date(basic_details.resign_date_2) : "",
            rejoin_date_1: basic_details.rejoin_date_1 && isNaN(Date.parse(basic_details.rejoin_date_1)) === false ? new Date(basic_details.rejoin_date_1) : "",
            rejoin_date_2: basic_details.rejoin_date_2 && isNaN(Date.parse(basic_details.rejoin_date_2)) === false ? new Date(basic_details.rejoin_date_2) : "",
            onboarded_from: basic_details.user_source ? basic_details.user_source : "",
            onboarded_by: basic_details.name ? basic_details.name : "",
            hiring_source: basic_details.hiring_source ? basic_details.hiring_source : "",
            comments: basic_details.comments ? basic_details.comments : "",
            name: role_details.username,
            employee_id: role_details.employee_id ? role_details.employee_id : "",
            role: role_details.role,
            bank_id: basic_details.bank_id,
            bank_account_number: basic_details.bank_account_number,
            confirm_bank_account_number: basic_details.bank_account_number,
            beneficiary_name: basic_details.beneficiary_name,
            ifsc_code: basic_details.ifsc_code,
            bank_branch_name: basic_details.bank_branch_name,
            npwp: basic_details.npwp ? basic_details.npwp : "",
            document_info: modified_documents,
            state: state_data,
            city: city_data,
            zone_id: defaultZone,
            business_line: role_details.business_line,
            joining_date: basic_details.joining_date,
            date_of_birth: basic_details.date_of_birth,
            anniversary_date: basic_details.anniversary_date,
            address: basic_details.address ? basic_details.address : "",
            street_address: basic_details.street_address ? basic_details.street_address : "",
            house_no: basic_details.house_number ? basic_details.house_number : "",
            user_state: basic_details.state_name,
            user_city: basic_details.city,
            pincode: basic_details.postal_code,
            incentive_percentage: basic_details.incentive_percentage ? basic_details.incentive_percentage.toString() : "",
            is_tax_booster_enable: basic_details.is_tax_booster_enable ? '1' : '0',
            email_id: role_details.email_id,
            password: basic_details.password,
            current_password: basic_details.current_password ? basic_details.current_password : "",
            change_password: basic_details.change_password ? basic_details.change_password : false,
            mobile_no: role_details.mobile_no,
            adhar_no: basic_details.adhar_no,
            reporting_role: role_details.reporting_role,
            priviousManagerId : role_details.previous_manager_id,
            reporting_manager: role_details.reporting_manager,
            current_occupation: basic_details.current_occupation ? basic_details.current_occupation : "",
            company_name: basic_details.company_name ? basic_details.company_name : "",
            source_id: basic_details.source ? basic_details.source : "",
            aro_so_assigned: basic_details.aro_so_assigned ? basic_details.aro_so_assigned : "",
            pan_no: basic_details.pan_no ? basic_details.pan_no : "",
            employment_type: basic_details.employment_type ? basic_details.employment_type : "",
            designation_id: basic_details.designation ? basic_details.designation : "",
            user_location: basic_details.user_location ? basic_details.user_location : "",
            tagging_id: basic_details.tagging_id ? basic_details.tagging_id : "",
            referral_name: basic_details.referral_name ? basic_details.referral_name : "",
            warning_date_1: basic_details.warning_date_1 && isNaN(Date.parse(basic_details.warning_date_1)) === false ? new Date(basic_details.warning_date_1) : "",
            warning_date_2: basic_details.warning_date_2 && isNaN(Date.parse(basic_details.warning_date_2)) === false ? new Date(basic_details.warning_date_2) : "",
            user_logout: user_logout,
            business_tagging: role_details.business_tagging || ''
        }

        if (user_detail.user_id) {
            params.user_id = user_detail.user_id
            params.status = user_detail.status
        }

        if (profile_data && Object.keys(profile_data).length > 0) {
            params.profile_image_name = profile_data.profile_image_name ? profile_data.profile_image_name : ""
            params.profile_image_path = profile_data.profile_image_path ? profile_data.profile_image_path : ""
        }

        MasterService.post('/sfa/user/create_user', params).then(function (response) {
            setLoading(false)
            if (response.data.status === 200) {

                if (response.data.message === "FAILED") {
                    if (response.data.data && Object.keys(response.data.data).length > 0) {
                        for (const key in response.data.data) {
                            if (Object.hasOwnProperty.call(response.data.data, key)) {
                                const element = response.data.data[key];
                                toast.error(element.error_message);
                            }
                        }

                    }
                } else {
                    let user_id = response.data.data.id
                    toast.success("User Updated Successfully");
                    historyLead('/view-user/' + user_id);
                }

            } else {
                if (response.data.error && response.data.error.length > 0) {
                    response.data.error.forEach(element => {
                        toast.error(element);
                    });
                }
            }
        }).catch(function (response) {
        });
    }

    const updateBasicDetail = () => {
        let temp_detail = { ...user_detail }
        let temp_basic_details = temp_detail.basic_details ? temp_detail.basic_details : {}
        temp_detail.basic_details = temp_basic_details
        setUserDetail(temp_detail)
    }

    const renderSwitch = (menu) => {  
        switch (true) { 
            case (menu.sub_menu_url ==='role_details' && queryParams.user_id === menu.sub_menu_url): 
                return <React.Fragment>  
                    {show_detail &&
                        <RoleDetails
                            stateList={stateList}
                            cityList={cityList}
                            user_detail={user_detail}
                            getConfigList={getConfigList}
                            updateBasicDetail={updateBasicDetail}
                            handleSave={handleSave}
                            setUserLogout={setUserLogout}
                            location_state={props.location?.state}
                        />
                    }
                </React.Fragment>;
           case (menu.sub_menu_url ==='basic_details' && queryParams.user_id === menu.sub_menu_url):
                return <React.Fragment>
                    {Object.keys(ConfigList).length > 0 && stateList.length > 0 && cityList.length > 0 &&
                        <BasicDetails
                            stateList={stateList}
                            cityList={cityList}
                            ConfigList={ConfigList}
                            user_detail={user_detail}
                            handleSave={handleSave}
                            handleReset={handleReset}
                            location_state={props.location?.state}
                        />
                    }
                </React.Fragment>;
             case (menu.sub_menu_url ==='documents' && queryParams.user_id === menu.sub_menu_url):
                return  <React.Fragment>
                    {Object.keys(ConfigList).length > 0 && stateList.length > 0 && cityList.length > 0 &&
                        <AddDocuments
                            ConfigList={ConfigList}
                            user_detail={user_detail}
                            handleSave={handleSave}
                            handleReset={handleReset}
                            location_state={props.location?.state}
                        />
                    }
                 </React.Fragment>;
             case (menu.sub_menu_url ==='preview_and_confirm' && queryParams.user_id === menu.sub_menu_url):
                return <React.Fragment>
                    {Object.keys(ConfigList).length > 0 && stateList.length > 0 && cityList.length > 0 &&
                        <PreviewConfirm
                            stateList={stateList}
                            cityList={cityList}
                            handleCreateUser={handleCreateUser}
                            user_detail={user_detail}
                            ConfigList={ConfigList}
                            handleReset={handleReset}
                        />
                    }
                 </React.Fragment>;
            default:

        }
    }

    const handleMenuClick = (sub_menu, index, e) => {
        const linkDisabled = (activePageId > sub_menu.id) ? false : true;
        if (linkDisabled)
            e.preventDefault()

        else {
            setActivePageId(sub_menu.id)
            let tempformFillStatusArr = formFillStatusArr
            if (!tempformFillStatusArr.includes(sub_menu.id)) {
                tempformFillStatusArr.push(sub_menu.id)
                setFormFillStatusList(tempformFillStatusArr)
            }
        }
    }
    
    return (
        < React.Fragment >
            <div className='add-user-outer'>
                {loading ? <Loader /> : null}
                <div className="add-user-form-sec">
                    <div className="menu-tab-left-panel">
                        <div className='add-user-detail-filed'><h2>Edit User</h2></div>
                        <div className="add-user-left-panel nav">
                            <ul>
                                {
                                    menuList.length !== 0 && menuList.map((menu, index) => (
                                        <li key={index}>
                                            <NavLink to={urlprefix + '/' + menu.sub_menu_url + "?=" + user_id} className={(formFillStatusArr && formFillStatusArr.includes(menu.id)) ? 'completed' : (activePageId === menu.id) ? 'active' : ''} aria-current="page" onClick={handleMenuClick.bind(this, menu, index)} >
                                                <span className="img-type"></span>
                                                {menu.sub_menu}
                                            </NavLink>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="route-files">
                        {
                            menuList.length !== 0 && menuList.map((menu, k) => (
                                <div key={k}>
                                    {renderSwitch(menu)}
                                </div>
                            ))
                        }
                    </div>
                </div>

            </div>

        </React.Fragment >
    )
}
//}
export default EditUser;
