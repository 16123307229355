import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import Img_preview from "../../webroot/images/preview_img.png";
// import Selfie_SSS_ID from "../../webroot/images/SelfieSSS_ID.png";
// import SSS_ID from "../../webroot/images/SSS_ID.png";
// import Bank_Statement from "../../webroot/images/BankStatement.png";

// import NPWP from "../../webroot/images/NPWP.png";
// import Agreement_Form from "../../webroot/images/AgreementForm.png";
import { Zone, defaultZone } from '../../config/constant';
import MasterService from '../../services/MasterService';
import Loader from '../common/loader';



const PreviewConfirm = (props) => {
    let { ConfigList, user_detail } = props
    const [roleList, setRoleDetails] = useState([])
    const [userDetail, setUserDetail] = useState(user_detail)
    const [reportingRoleList, setReportingRoleList] = useState([])
    // const [ReferralName, setReferralName] = useState([])
    const [reportingManagerList, setReportingManagerList] = useState([])
    // const [bankList, setBankList] = useState([])
    const [createDisabled, setCreateDisabled] = useState(false)
    const [localityList, setLocalityList] = useState([])
    const [loading, setLoading] = useState(false)
    const [is_edit, setEdit] = useState(user_detail && user_detail.user_id && user_detail.user_id !== "" ? true : false)
    const historyLead = useNavigate();

    useEffect(() => {
        if (ConfigList.length === 0) {
            props.handleReset()
            if (is_edit) {
                historyLead("/edit-user/role_details?=" + userDetail.user_id);
            } else {
                historyLead("/add-user/role_details");
            }
        }
        getRoleList()
        getUsersByRole()
        getReportingRole()
        getBankList()
        getReferralUserList()
        getLocalityList()
        if (userDetail.user_id) {
            getUserLocationDetail(userDetail.user_id)
        }
    }, [])

    const getUserLocationDetail = async (user_id) => {
        if (userDetail.role_details.city && userDetail.role_details.city.length > 0) {

        } else {
            setLoading(true);
            await MasterService.post('/sfa/user/get-user_location', { user_id }).then(async function (response) {
                if (response.data.status === 200) {
                    let data = response.data.data
                    let tempUserDetail = { ...userDetail };

                    let state_data = data.location_response.map(({ state_id }) => state_id)
                    state_data = state_data.filter((item, i, ar) => ar.indexOf(item) === i);
                    let city_data = data.location_response.map(({ city_id }) => city_id)
                    city_data = city_data.filter((item, i, ar) => ar.indexOf(item) === i);

                    tempUserDetail.role_details.state = state_data
                    tempUserDetail.role_details.city = city_data
                    setUserDetail(tempUserDetail)
                    setLoading(false)
                }
            })
        }
    }

    const getLocalityList = async () => {
        if(userDetail.basic_details){
            await MasterService.getLocalityList(userDetail.basic_details.city).then((res) => {
                if(res && res.data && res.data.data && res.data.data.length){
                    let locality_list = res.data.data.map(elm => ({ id: elm.id, label: elm.value, value: elm.id }));
                    setLocalityList(locality_list)
                }
            })
        } 
    }

    const getReferralUserList = async () => {
        MasterService.post('/sfa/user/get_referral_user', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    // var referral_list = response.data.data.map(elm => ({ id: elm.id, label: elm.name + " (" + elm.id + ")", value: elm.id }));
                    // setReferralName(referral_list);
                }
            })
            .catch(function (response) {
            });
    }

    const getBankList = async () => {
        MasterService.post('/sfa/user/bank-list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    // var bank_list = response.data.data.filter(elm => elm.bankId === basic_details.bank_id);
                    // setBankList(bank_list);
                }
            })
            .catch(function (response) {
            });
    }

    const getRoleList = async () => {
        MasterService.post('/sfa/user/role_list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    let role_list = response.data.data.filter(elm => elm.id === userDetail.role_details.role);
                    setRoleDetails(role_list)
                }
            })
            .catch(function (response) {
            });
    }

    const getUsersByRole = async () => {
        MasterService.post('/sfa/user/get-users', { role_id: userDetail.role_details.reporting_role })
            .then(function (response) {
                if (response.data.status === 200) {
                    let reporting_manager_list = response.data.data.user_list.filter(elm => elm.id === userDetail.role_details.reporting_manager);
                    setReportingManagerList(reporting_manager_list)
                }
            })
            .catch(function (response) {
            });
    }
    const getReportingRole = async () => {
        MasterService.post('/sfa/user/reporting_role_list', { role_id: userDetail.role_details.role })
            .then(function (response) {
                if (response.data.status === 200) {
                    let reporting_role_list = response.data.data.filter(elm => elm.id === userDetail.role_details.reporting_role);
                    setReportingRoleList(reporting_role_list)
                }
            })
            .catch(function (response) {
            });
    }

    const handleClickCancel = () => {
        if (user_detail?.user_id) {
            historyLead('/view-user/' + user_detail?.user_id,{ replace: true })
        }
    }

    const handleClick = async () => {
        setCreateDisabled(true)
        let result = isValidate();
        if (result.formIsValid) { 
            await props.handleCreateUser()
        } else { 
            let pathname = ""
            if (result.basic_detail_error === "true") {
                if (is_edit) {
                    pathname = '/edit-user/basic_details'
                } else {
                    pathname = '/add-user/basic_details'
                }
            }
            if (result.role_error === "true") {
                if (is_edit) {
                    pathname = '/edit-user/role_details'
                } else {
                    pathname = '/add-user/role_details'
                }
            }
            if (result.document_error === "true") {
                if (is_edit) {
                    pathname = '/edit-user/documents'
                } else {
                    pathname = '/add-user/documents'
                }
            }

            if (is_edit) {
                historyLead(pathname + "?=" + userDetail.user_id, { tempErrors: result.tempErrors });
            } else {
                historyLead(pathname, { tempErrors: result.tempErrors });
            }
        }
    }

    const isValidate = () => {
        let tempUserDetail = { ...userDetail };
        let formIsValid = true;
        let tempErrors = {};
        let role_error = "false"
        let basic_detail_error = "false"
        let document_error = "false"
        // if (is_edit && tempUserDetail.basic_details["password"] === defaultPassword) {
        //     tempUserDetail.basic_details["password"] = ""
        //     tempUserDetail.basic_details["confirm_password"] = ""
        // }
        if (!tempUserDetail.role_details["state"]) {
            formIsValid = false;
            role_error = "true";
            tempErrors["state"] = 'State is required';
        }
        if (!tempUserDetail.role_details["city"]) {
            formIsValid = false;
            role_error = "true";
            tempErrors["city"] = 'City is required';
        }
        if (!tempUserDetail.role_details["role"]) {
            formIsValid = false;
            role_error = "true";
            tempErrors["role"] = 'Role is required';
        }
        if (tempUserDetail.role_details["reporting_role"]!==5 && !tempUserDetail.role_details["reporting_role"]) {
            formIsValid = false;
            tempErrors["reporting_role"] = 'Reporting Role is required';
        }
        // if (tempUserDetail.role_details["reporting_manager"]!==5 && !tempUserDetail.role_details["reporting_manager"]) {
        //     formIsValid = false;
        //     role_error = "true";
        //     tempErrors["reporting_manager"] = 'Reporting Manager is required';
        // }

        if (Object.keys(tempErrors).length > 0) {
            formIsValid = false
        }
        let response = {
            formIsValid: formIsValid,
            basic_detail_error: basic_detail_error,
            tempErrors: tempErrors,
            role_error: role_error,
            document_error: document_error
        }
        return response;
    }

    const handleEdit = (pathname) => {
        let url = ""
        if (is_edit) {
            url = "/edit-user/" + pathname + "?=" + userDetail.user_id
            historyLead(url);
        } else {
            url = "/add-user/" + pathname
            historyLead(url);
        }
    }

    let { role_details, basic_details } = userDetail

    return (
        <React.Fragment>
            <div className='preview-confirmation p-xxl add-user-detail-filed'>
                {loading ? <Loader /> : null}
                <div className='top-heading d-flex'>
                    <h2>Role Details</h2>
                    <Link onClick={() => handleEdit("role_details")} className='edit-opt'>Edit</Link>
                </div>

                <div className='d-flex'>
                    <div className='role-detail-preview'>
                        <h3>Basic Details</h3>
                        <ul>
                            <li>
                                <span>Username</span> 
                                <label>{role_details.username ? role_details.username : "-"}</label> 
                            </li> 
                            <li>
                                <span>Email ID</span> 
                                <label>{role_details.email_id ? role_details.email_id : "-"}</label> 
                            </li>  
                            <li>
                                <span>Mobile No</span> 
                                <label>{role_details.mobile_no ? role_details.mobile_no : "-"}</label> 
                            </li>  
                            <li>
                                <span>Employee ID</span> 
                                <label>{role_details.employee_id ? role_details.employee_id : "-"}</label> 
                            </li>   
                        </ul>
                    </div> 
                </div>
                <div><br/></div>
                <div className='d-flex'>
                    <div className='role-detail-preview'>
                        <h3>Business Vertical</h3>
                        <ul>  
                            <li>
                                <span>Business</span>
                                <label>Ambak</label>
                            </li>
                            <li>
                                <span>Vertical</span>
                                <label>Home Loan</label>
                            </li>
                        </ul>
                    </div>
                    <div className='role-detail-preview'>
                        <h3>Map Region</h3>
                        <ul>
                            {/* {role_details.zone_id && */}
                            <li>
                                <span>Zone</span>
                                <label>{Zone.filter(e => e.id === defaultZone)[0].label}</label>

                            </li>
                            {/* } */}
                            {role_details.state &&
                                <li>
                                    <span>State</span>
                                    <label>{role_details.state ? role_details.state.length : "No"} States</label>
                                </li>
                            }
                            {role_details.city &&
                                <li>
                                    <span>City</span>
                                    <label>{role_details.city ? role_details.city.length : "No"} Cities</label>
                                </li>
                            }
                        </ul>
                    </div>
                </div>

                <div className='d-flex m-xl-t p-sm-t br-bottom'>
                    <div className='role-detail-preview'>
                        <h3>Role and Reporting</h3>
                        <ul>
                            {roleList && roleList.length > 0 &&
                                <li>
                                    <span>Role</span>
                                    <label>{roleList[0].name}</label>
                                </li>
                            }
                            {reportingRoleList.length > 0 &&
                                <li>
                                    <span>Reporting Role</span>
                                    <label>{reportingRoleList[0].name}</label>
                                </li>
                            }
                            {reportingManagerList.length > 0 &&
                                <li>
                                    <span>Reporting Manager</span>
                                    <label>{reportingManagerList[0].name}</label>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
                <div className='br-top-flex'>
                    {is_edit ? <div className="t-right">
                        <button className='btn-line m-xs-l' onClick={handleClickCancel.bind(this)}>
                            Cancel
                        </button>
                    </div> : null}
                    <div className="t-right">
                        <button disabled={createDisabled} className='btn-primary' onClick={handleClick.bind(this)}>
                            {is_edit ? "Update User" : "Create User"}
                        </button>
                    </div>
                </div>



            </div>

        </React.Fragment>
    )
}
export default PreviewConfirm;