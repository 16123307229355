import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams, NavLink } from 'react-router-dom';
import RoleDetails from "./AddUserRoleDetails"
import BasicDetails from "./BasicDetails"
import AddDocuments from "./AddDocuments"
import PreviewConfirm from "./PreviewConfirm"
import MasterService from '../../services/MasterService';
import { toast } from 'react-toastify';
import {defaultZone } from '../../config/constant';
import Loader from '../common/loader';
import secureStorage from '../../config/encrypt';

const AddUser = (props) => {
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
    const [activePageId, setActivePageId] = useState('');
    const [formFillStatusArr, setFormFillStatusList] = useState([]);
    const [user_detail, setUserDetail] = useState({});
    const [loading, setLoading] = useState(false);
    const [ConfigList, setConfigList] = useState([])
    const queryParams = useParams();
    let authUser = secureStorage.getItem('loginUserInfo');
    const LOGIN_UID = authUser.id || 0; 
 
    const urlprefix = '/add-user';
    const menuList = [
        {
            id: 1,
            index: 1,
            sub_menu: "Role Details",
            sub_menu_url: "role_details"
        },
        {
            id: 4,
            index: 4,
            sub_menu: "Preview and Confirm",
            sub_menu_url: "preview_and_confirm"
        }]

    const historyLead = useNavigate();

    useEffect(() => {
        getStateList()
        getCityList()
        checkValidPath()
    }, [])


    const checkValidPath = () => {
        if (Object.keys(user_detail).length === 0) {
            handleReset()
            historyLead('/add-user/role_details',{ replace: true });
        }
    }

    const getConfigList = async (role_id) => {
        MasterService.post('/sfa/user/get-config', { role_id: role_id })
            .then(function (response) {
                if (response.data.status === 200) {
                    setConfigList(response.data.data)
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
            });
    }

    const getStateList = async () => {
        MasterService.post('/sfa/user/state_list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    var state_list = response.data.data.map(elm => ({ id: elm.id, label: elm.state_name, value: elm.id }));
                    setStateList(state_list);
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
            });
    }

    const getCityList = async (state_ids) => {
        MasterService.post('/sfa/user/city_list', { state_ids })
            .then(function (response) {
                if (response.data.status === 200) {
                    var city_list = response.data.data.map(elm => ({ id: elm.id, label: elm.city_name, value: elm.id, state_id: elm.state_id }));
                    setCityList(city_list);
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
            });
    }

    const handleReset = () => {
        setActivePageId('')
        setFormFillStatusList([])
    }

    const handleSave = async (element, name) => {
        let temp_user_detail = { ...user_detail }
        temp_user_detail[name] = element
        setUserDetail(temp_user_detail)
        let index = menuList.findIndex(x => x.sub_menu_url === name);
        let next_element = menuList[index + 1]
        let current_element = menuList[index]
        setActivePageId(next_element.id)
        let tempformFillStatusArr = formFillStatusArr
        if (!tempformFillStatusArr.includes(current_element.id)) {
            tempformFillStatusArr.push(current_element.id)
        }
        setFormFillStatusList(tempformFillStatusArr)
    }

    const uploadProfileImages = async (profile_image_file) => {
        let profile_data = {}
        var formData = new FormData();
        formData.append("dealer_id", "0");
        formData.append("upload_type", "dealer_docs");
        formData.append("visit_id", `user/0`);
        formData.append("images", profile_image_file);
        await MasterService.uploadFilesToS3(formData, {
            "Content-Type": "multipart/form-data",
        }).then((result) => {
            if (result.data.status === 200) {
                profile_data.profile_image_name = result.data.data[0].file_name;
                profile_data.profile_image_path = result.data.data[0].file_url;
            }
            toast.success("Profile Image uploaded")
        })
        return profile_data
    }

    // const uploadImages = async (added_documents) => {
    //     let document_info = []
    //     for (let index = 0; index < added_documents.length; index++) {
    //         let element = added_documents[index]
    //         let doc_object = {}
    //         if (element.doc_url.includes("blob")) {
    //             var formData = new FormData();
    //             formData.append("dealer_id", "0");
    //             formData.append("upload_type", "dealer_docs");
    //             formData.append("visit_id", `user/0`);
    //             formData.append("images", element.doc_file);
    //             await MasterService.uploadFilesToS3(formData, {
    //                 "Content-Type": "multipart/form-data",
    //             }).then((result) => {
    //                 if (result.data.status === 200) {
    //                     doc_object.document_id = element.document_id
    //                     doc_object.doc_url = result.data.data[0].file_url;
    //                     toast.success(element.doc_title + " uploaded")
    //                 }
    //                 document_info.push(doc_object)
    //             })
    //         } else {
    //             doc_object.document_id = element.document_id
    //             doc_object.doc_url = element.doc_url
    //             document_info.push(doc_object)
    //         }
    //     }
    //     return document_info
    // }

    const handleCreateUser = async () => {
        setLoading(true)
        let profile_data = {}
        let { role_details, basic_details } = user_detail
        if (basic_details && basic_details.profile_image_file) {
            profile_data = await uploadProfileImages(basic_details.profile_image_file)

        }

       // let modified_documents = await uploadImages(documents)
        // let state_data = stateList.filter((e) => role_details.state.includes(e.id))
        let city_data = cityList.filter((e) => role_details.city.includes(e.id))
        let state_data = city_data.map(({ state_id }) => state_id)
        state_data = state_data.filter((item, i, ar) => ar.indexOf(item) === i);
 
        let params = {
            userId:LOGIN_UID,
            name: role_details?.username || '',
            employee_id: role_details.employee_id ? role_details.employee_id : "",
            role: role_details.role,
            bank_id: basic_details?.bank_id || 0,
            bank_account_number: basic_details?.bank_account_number || '',
            confirm_bank_account_number: basic_details?.confirm_bank_account_number || '',
            beneficiary_name: basic_details?.beneficiary_name || '',
            ifsc_code: basic_details?.ifsc_code || '',
            bank_branch_name: basic_details?.bank_branch_name || '',
            npwp: basic_details?.npwp ? basic_details.npwp : "",
            document_info: [],
            state: state_data,
            city: city_data,
            zone_id: defaultZone,
            business_line: role_details?.business_line || '',
            joining_date: basic_details?.joining_date || '',
            date_of_birth: basic_details?.date_of_birth || '',
            anniversary_date: basic_details?.anniversary_date || '',
            address: basic_details?.address ? basic_details.address : "",
            street_address: basic_details?.street_address ? basic_details.street_address : "",
            house_no: basic_details?.house_number ? basic_details.house_number : "",
            user_state: basic_details?.state_name || '',
            user_city: basic_details?.city || '',
            pincode: basic_details?.postal_code || '',
            incentive_percentage: basic_details?.incentive_percentage ? basic_details.incentive_percentage.toString() : "",
            is_tax_booster_enable: basic_details?.is_tax_booster_enable ? '1' : '0',
            email_id: role_details?.email_id || '',
            password: basic_details?.password || '',
            current_password: basic_details?.current_password ? basic_details.current_password : "",
            change_password: basic_details?.change_password ? basic_details.change_password : false,
            mobile_no: role_details?.mobile_no || '',
            adhar_no: basic_details?.adhar_no || '',
            reporting_role: role_details.reporting_role,
            reporting_manager: role_details.reporting_manager,
            current_occupation: basic_details?.current_occupation ? basic_details.current_occupation : "",
            company_name: basic_details?.company_name ? basic_details.company_name : "",
            source_id: basic_details?.source ? basic_details.source : "", 
            pan_no: basic_details?.pan_no ? basic_details.pan_no : "",
            employment_type: basic_details?.employment_type ? basic_details.employment_type : "",
            designation_id: basic_details?.designation ? basic_details.designation : "",
            user_location: basic_details?.user_location ? basic_details.user_location : "",
            tagging_id: basic_details?.tagging_id ? basic_details.tagging_id : "",
            referral_name: basic_details?.referral_name ? basic_details.referral_name : "",
            business_tagging: role_details?.business_tagging || '',
        }

        if (user_detail.user_id) {
            params.user_id = user_detail.user_id
        }
        if (profile_data && Object.keys(profile_data).length > 0) {
            params.profile_image_name = profile_data.profile_image_name ? profile_data.profile_image_name : ""
            params.profile_image_path = profile_data.profile_image_path ? profile_data.profile_image_path : ""
        }


        MasterService.post('/sfa/user/create_user', params).then(function (response) {
            setLoading(false)
            if (response.data.status) {
                if (response.data.message === "FAILED") {
                    if (response.data.data && Object.keys(response.data.data).length > 0) {
                        for (const key in response.data.data) {
                            if (Object.hasOwnProperty.call(response.data.data, key)) {
                                const element = response.data.data[key];
                                toast.error(element.error_message);
                            }
                        }
                    }
                } else {
                    let user_id = response.data.data.id
                    toast.success("User Updated Successfully");
                    historyLead('/view-user/' + user_id);
                }
            } else {
                if(response.data.message !== ""){
                    toast.error(response.data.message);
                }else if (response.data.error && response.data.error.length > 0) {
                    response.data.error.forEach(element => {
                        toast.error(element);
                    });
                }
            }
        }).catch(function (response) {
        });
    }

    const updateBasicDetail = () => {
        let temp_detail = { ...user_detail }
        let temp_basic_details = temp_detail.basic_details ? temp_detail.basic_details : {}
        temp_basic_details["current_occupation"] = ""
        temp_basic_details["referral_name"] = ""
        temp_basic_details["company_name"] = ""
        temp_basic_details["designation"] = ""
        temp_basic_details["tagging_id"] = ""
        temp_basic_details["source"] = ""
        temp_basic_details["is_tax_booster_enable"] = false
        temp_basic_details["incentive_percentage"] = 0
        temp_detail.basic_details = temp_basic_details
        setUserDetail(temp_detail)
    }

    const renderSwitch = (menu) => {
        switch (true) {
            case (menu.sub_menu_url ==='role_details' && queryParams.pagename === menu.sub_menu_url):
                return <React.Fragment> 
                    {stateList.length > 0 && cityList.length > 0 &&
                        <RoleDetails
                            stateList={stateList}
                            cityList={cityList}
                            user_detail={user_detail}
                            updateBasicDetail={updateBasicDetail}
                            getConfigList={getConfigList}
                            handleSave={handleSave}
                            location_state={props.location?.state}
                        />
                    }   
                </React.Fragment>; 
            case (menu.sub_menu_url ==='basic_details' && queryParams.pagename === menu.sub_menu_url):
                return <React.Fragment>
                    {Object.keys(ConfigList).length > 0 && stateList.length > 0 && cityList.length > 0 &&
                        <BasicDetails
                            stateList={stateList}
                            cityList={cityList}
                            ConfigList={ConfigList}
                            user_detail={user_detail}
                            handleSave={handleSave}
                            handleReset={handleReset}
                            location_state={props.location?.state}
                        />
                    }
                </React.Fragment>; 
            case (menu.sub_menu_url ==='documents' && queryParams.pagename === menu.sub_menu_url):
                return <React.Fragment>
                    {Object.keys(ConfigList).length > 0 && stateList.length > 0 && cityList.length > 0 &&
                        <AddDocuments
                            ConfigList={ConfigList}
                            user_detail={user_detail}
                            handleSave={handleSave}
                            handleReset={handleReset}
                            location_state={props.location?.state}
                        />
                    }
                </React.Fragment>; 
            case (menu.sub_menu_url ==='preview_and_confirm' && queryParams.pagename === menu.sub_menu_url):

                return <React.Fragment>
                    {Object.keys(ConfigList).length > 0 && stateList.length > 0 && cityList.length > 0 &&
                        <PreviewConfirm
                            stateList={stateList}
                            cityList={cityList}
                            handleCreateUser={handleCreateUser}
                            user_detail={user_detail}
                            ConfigList={ConfigList}
                            handleReset={handleReset}
                        />
                    }
                </React.Fragment>;
            default:
                

        }
    }

    const handleMenuClick = (sub_menu, index, e) => {
        const linkDisabled = (activePageId > sub_menu.id) ? false : true;
        if (linkDisabled)
            e.preventDefault()

        else {
            setActivePageId(sub_menu.id)
            let tempformFillStatusArr = formFillStatusArr
            if (!tempformFillStatusArr.includes(sub_menu.id)) {
                tempformFillStatusArr.push(sub_menu.id)
                setFormFillStatusList(tempformFillStatusArr)
            }
        }
    }

    return (
        < React.Fragment >
            <div className='add-user-outer'>
                {loading ? <Loader /> : null}
                <div className="add-user-form-sec">
                    <div className="menu-tab-left-panel">
                        <div className='add-user-detail-filed'><h2>Add User</h2></div>
                        <div className="add-user-left-panel nav">
                            <ul>
                                {
                                    menuList.length !== 0 && menuList.map((menu, index) => (
                                        <li key={index}>
                                            <NavLink to={urlprefix + '/' + menu.sub_menu_url} className={(formFillStatusArr && formFillStatusArr.includes(menu.id)) ? 'completed' : (activePageId === menu.id) ? 'active' : ''} aria-current="page" onClick={handleMenuClick.bind(this, menu, index)} >
                                                <span className="img-type"></span>
                                                {menu.sub_menu}
                                            </NavLink>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="route-files">
                        {
                           queryParams.pagename && menuList.length !== 0 && menuList.map((menu, k) => (
                                <div key={k}>  
                                    {renderSwitch(menu)} 
                                </div>
                            ))
                        }   
                    </div>
                </div>

            </div>

        </React.Fragment >
    )
}
//}
export default AddUser;
